.loading-indicator:before {
  content: '';
  /* background: #000000cc; */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  background: url(images/new_loading.gif) center center no-repeat;  
  content: ' ';
  position: fixed;
  width: 100%;
  height: 300px;
  top: 30%;
  left: 0;
  z-index: 1001;
  text-align: center;
  background-size: 300px;
  opacity: 0.4;

}
