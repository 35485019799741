.ag-theme-balham .ag-cell {
  text-align: center;
}

.story-chart {
  box-sizing: border-box;
  background: white;
}
.story-chart-dark {
  box-sizing: border-box;
  background: #1a1b20;
}
#root {
  background-color: blanchedalmond;
}
#story-root {
  padding: 20px;
  width: 100%;
  height: 400px;
  position: relative;
  box-sizing: border-box;
}
#story-root + div table {
  border: 1px solid gray;
}
#story-root + div table th {
  border: 1px solid gray;
  padding: 5px;
}
#story-root + div table td {
  border: 1px solid gray;
  padding: 5px;
}
.Pane.vertical.Pane1 {
  background: red;
}
